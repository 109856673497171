import "./App.css";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isMobile } from "react-device-detect";

function App() {
  const registrationAccountCheckbox = useRef(null);
  const emailInput = useRef(null);
  const privacyCheckbox = useRef(null);
  const [amount, setAmount] = useState(5);
  const [email, setEmail] = useState("");
  const [checkedCheckbox, setCheckedCheckbox] = useState("");
  const [isUserAccountCrationAgreed, setUserCreationAgreement] = useState(
    false
  );
  const [isPrivacyChecked, checkPrivacy] = useState(false);
  const [isOnlyEmailRevealed, setRevealOnlyEmail] = useState(false);
  const [isEmailAndCheckboxRevealed, setEmailAndCheckboxRevealed] = useState(
    false
  );

  const [createOption, setCreateOption] = useState("");
  const apiUrl = "https://psitulisko.herokuapp.com";

  const createUserPaymentSession = async () => {
    let result;

    const createUserPaymentSessionConfig = {
      method: "POST",
      url: `${apiUrl}/api/payments/create_donation_session/`,
      data: {
        email: email,
        amount: amount,
        create_account: isUserAccountCrationAgreed,
      },
    };

    await axios(createUserPaymentSessionConfig)
      .then((response) => {
        result = response.data;
      })
      .catch(({ error, response }) => {
        if (response.data.message) {
          const notify = () => toast.error(`${response.data.message}`);
          notify();
          emailInput.current.classList.add("input-error");
        }
      });
    return result;
  };

  const handlePaymentButton = async () => {
    if (!isPrivacyChecked) {
      privacyCheckbox.current.classList.add("checkbox-error");
      const notify = () =>
        toast.error(`Proszę zaakceptować politykę prywatności`);
      notify();
      return;
    }

    if (createOption == "") {
      const notify = () => toast.error(`Proszę wybrać jedną z opcji`);
      notify();
      return;
    }

    if (createOption == "hasAccount" && email == "") {
      const notify = () => toast.error(`Proszę wpisać poprawny email`);
      notify();
      emailInput.current.classList.add("input-error");
      return;
    }
    if (createOption == "hasNoAccountCreateNew" && email == "") {
      const notify = () => toast.error(`Proszę wpisać poprawny email`);
      notify();
      emailInput.current.classList.add("input-error");
      return;
    }
    if (
      createOption == "hasNoAccountCreateNew" &&
      !isUserAccountCrationAgreed
    ) {
      const notify = () =>
        toast.error(`Proszę zaakceptować regulamin aplikacji`);
      notify();
      emailInput.current.classList.add("input-error");
      return;
    }

    const stripePromise = await loadStripe(
      "pk_live_51HuHFCA2ZU5USHEOpNPpCXQYiynPrrQiqhQEeSpjJG9HbtITX5mTqsmZ1yhGCHHgrWd2L4seC8OwZWsmYyV7ODNp00Ce29OxwJ"
    );
    let response;

    response = await createUserPaymentSession();

    if (response !== undefined) {
      const sessionId = await response.id;

      const stripe = await stripePromise;

      await stripe.redirectToCheckout({
        sessionId: sessionId,
      });
    }
  };

  const clearEmailInput = () => {
    setEmailAndCheckboxRevealed(true);
  };

  const onCheckBoxCheck = (value) => {
    setCheckedCheckbox(value);

    switch (value) {
      case "pln5":
        setAmount(5);
        break;
      case "pln10":
        setAmount(10);
        break;
      case "pln20":
        setAmount(20);
        break;
      case "pln50":
        setAmount(50);
        break;
      case "pln100":
        setAmount(100);
        break;
      default:
        break;
    }
  };

  const setDonationValue = (mode) => {
    setCheckedCheckbox("");
    if (mode === "minus" && amount > 5) {
      setAmount(amount - 5);
    }
    if (mode === "plus") {
      setAmount(amount + 5);
    }
  };

  const removeErrorClass = () => {
    if (emailInput.current.classList.contains("input-error")) {
      emailInput.current.classList.remove("input-error");
    }
  };

  const revealOnlyEmail = () => {
    setRevealOnlyEmail(!isOnlyEmailRevealed);
  };

  const revealEmailandCheckbox = () => {
    setEmailAndCheckboxRevealed(!isEmailAndCheckboxRevealed);
  };

  const pickCreateOption = (value) => {
    setCreateOption(value);
  };

  const setPrivacyChecked = () => {
    checkPrivacy(!isPrivacyChecked);
    if (privacyCheckbox.current.classList.contains("checkbox-error")) {
      privacyCheckbox.current.classList.remove("checkbox-error");
    }
  };

  return (
    <div className="donationApp">
      <div className="donationApp__container">
        <div className="donationApp__banner">
          <a href="https://psitulisko.pl/">
            <div className="donationApp__banner__logo"></div>
          </a>
          <span className="donationApp__banner__subheader">Przekaż datek</span>
        </div>
        <main>
          <a href="https://psitulisko.pl/">
            <button className="donationApp__backButton">
              <div className="donationApp__backButton__icon"></div>
              <span className="donationApp__backButton__text">
                {isMobile ? "Powrót" : "Wróć na stronę główną"}
              </span>
            </button>
          </a>

          <div className="donationApp__content">
            <div className="donationApp__form">
              <span className="donationApp__form__textRow">
                Wysokosć datku:{" "}
              </span>
              <div className="donationApp__input-container donationApp__input-container__amount">
                <button
                  onClick={() => setDonationValue("minus")}
                  className="button button-minus"
                ></button>
                <div>{amount} zł</div>
                <button
                  onClick={() => setDonationValue("plus")}
                  className="button button-add"
                ></button>
              </div>
              <span className="donationApp__form__textRow">lub wybierz</span>

              <div className="donationApp__checkbox-container">
                <div className="donationApp__checkbox-round">
                  <input
                    type="checkbox"
                    id="pln5"
                    checked={checkedCheckbox === "pln5"}
                    onChange={() => onCheckBoxCheck("pln5")}
                  />
                  <label for="pln5"></label>
                  <span className="donationApp__checkbox-name">5 zł</span>
                </div>

                <div className="donationApp__checkbox-round">
                  <input
                    type="checkbox"
                    id="pln10"
                    checked={checkedCheckbox === "pln10"}
                    onChange={() => onCheckBoxCheck("pln10")}
                  />
                  <label for="pln10"></label>
                  <span className="donationApp__checkbox-name">10 zł</span>
                </div>

                <div className="donationApp__checkbox-round">
                  <input
                    type="checkbox"
                    id="pln20"
                    checked={checkedCheckbox === "pln20"}
                    onChange={() => onCheckBoxCheck("pln20")}
                  />
                  <label for="pln20"></label>
                  <span className="donationApp__checkbox-name">20 zł</span>
                </div>

                <div className="donationApp__checkbox-round">
                  <input
                    type="checkbox"
                    id="pln50"
                    checked={checkedCheckbox === "pln50"}
                    onChange={() => onCheckBoxCheck("pln50")}
                  />
                  <label for="pln50"></label>
                  <span className="donationApp__checkbox-name">50 zł</span>
                </div>

                <div className="donationApp__checkbox-round">
                  <input
                    type="checkbox"
                    id="pln100"
                    checked={checkedCheckbox === "pln100"}
                    onChange={() => onCheckBoxCheck("pln100")}
                  />
                  <label for="pln100"></label>
                  <span className="donationApp__checkbox-name">100 zł</span>
                </div>
              </div>

              <div className="donationApp__radio-container">
                <div
                  className="donationApp__radio-row "
                  onClick={() => pickCreateOption("hasNoAccountDoNotCreateNew")}
                >
                  <input
                    onChange={(e) => pickCreateOption(e.target.value)}
                    name="accountCreationRadio"
                    type="radio"
                    value="hasNoAccountDoNotCreateNew"
                    checked={createOption == "hasNoAccountDoNotCreateNew"}
                  ></input>
                  <label>
                    Oświadczam, iż nie chcę utworzyć konta w Aplikacji
                    Psitulisko.
                  </label>
                </div>

                <div
                  className="donationApp__radio-row"
                  onClick={() => pickCreateOption("hasAccount")}
                >
                  <input
                    name="accountCreationRadio"
                    type="radio"
                    value="hasAccount"
                    onChange={(e) => pickCreateOption(e.target.value)}
                    checked={createOption == "hasAccount"}
                  ></input>
                  <label>
                    Oświadczam, iż posiadam konto w Aplikacji Psitulisko.
                  </label>
                </div>
                <div>
                  {createOption == "hasAccount" && (
                    <div className="donationApp__row">
                      <div className="donationApp__input-container donationApp__input-container__email">
                        <input
                          onChange={(e) => setEmail(e.target.value)}
                          ref={emailInput}
                          className={`donationApp__input`}
                          type="email"
                          placeholder="Wpisz swoj email"
                          value={email}
                          onClick={removeErrorClass}
                        ></input>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="donationApp__radio-row"
                  onClick={() => pickCreateOption("hasNoAccountCreateNew")}
                >
                  <input
                    onChange={(e) => pickCreateOption(e.target.value)}
                    name="accountCreationRadio"
                    type="radio"
                    value="hasNoAccountCreateNew"
                    checked={createOption == "hasNoAccountCreateNew"}
                  ></input>
                  <label>
                    Oświadczam, iż chcę utworzyć Konto w Aplikacji Psitulisko.
                  </label>
                </div>
                {createOption == "hasNoAccountCreateNew" && (
                  <div className="donationApp__row">
                    <div className="donationApp__input-container donationApp__input-container__email">
                      <input
                        className={`donationApp__input`}
                        onChange={(e) => setEmail(e.target.value)}
                        ref={emailInput}
                        type="email"
                        placeholder="Wpisz swoj email"
                        value={email}
                        onClick={removeErrorClass}
                      ></input>
                    </div>
                    <div
                      className="donationApp__input-container donationApp__input-container__checkbox"
                      onClick={() =>
                        setUserCreationAgreement(!isUserAccountCrationAgreed)
                      }
                    >
                      <input
                        className="donationApp__checkbox"
                        id="donationAppCheckbox"
                        type="checkbox"
                        ref={registrationAccountCheckbox}
                        onChange={() =>
                          setUserCreationAgreement(!isUserAccountCrationAgreed)
                        }
                        checked={isUserAccountCrationAgreed}
                      ></input>
                      <label for="#donationAppCheckbox">
                        Oświadczam, iż zapoznałem/am się i akceptuję
                        <a href="https://psitulisko.pl/regulamin-aplikacji-psitulisko/">
                          {` Regulamin Aplikacji Psitulisko`}
                        </a>
                        .
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div className="donationApp__input-container donationApp__input-container__checkbox">
                <input
                  className="donationApp__checkbox"
                  id="donationAppPrivacyCheckbox"
                  type="checkbox"
                  ref={privacyCheckbox}
                  onChange={() => setPrivacyChecked()}
                ></input>
                <label for="#donationAppPrivacyCheckbox">
                  Zapoznałem/am się z
                  <a href="https://psitulisko.pl/polityka-prywatnosci-aplikacja/">
                    {` Polityką prywatności`}.
                  </a>
                </label>
              </div>
            </div>
            <button
              className="donationApp__submitBtn"
              onClick={handlePaymentButton}
            >
              Wpłać datek
            </button>
          </div>
        </main>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
